export const Themes = [
    {
        'name': 'default',

        'background-color': '#ffffff',
        'titlebar-color': '#f4f4f4',
        'titlebar-text-color': '#000000',
        'breadcrumbs-color': '#ffffff',
        'breadcrumbs-text-color': '#000000',
        'main-button-color': '#f4f4f4',
        'main-button-text-color': '#000000',
        'box-header-color': '#f4f4f4',
        'box-header-text-color': '#000000',
        'box-content-color': '#ffffff',
        'box-content-text-color': '#000000',
        'dialog-header-color': '#f4f4f4',
        'dialog-header-text-color': '#000000',
        'dialog-content-color': '#ffffff',
        'dialog-content-text-color': '#000000',
        'header-button-color': '#aaaaaa',
        'header-button-text-color': '#000000',
        'header-success-button-hover-color': '#9eebcf',
        'header-success-button-text-hover-color': '#000000',
        'header-fail-button-hover-color': '#ff725c',
        'header-fail-button-text-hover-color': '#000000'
    },
    {
        'name': 'blue',

        'background-color': '#D3D3D3',
        'titlebar-color': '#08215c',
        'titlebar-text-color': '#ffffff',
        'breadcrumbs-color': '#D3D3D3',
        'breadcrumbs-text-color': '#000A1B',
        'main-button-color': '#08215c',
        'main-button-text-color': '#ffffff',
        'box-header-color': '#08215c',
        'box-header-text-color': '#ffffff',
        'box-content-color': '#ffffff',
        'box-content-text-color': '#000A1B',
        'dialog-header-color': '#08215c',
        'dialog-header-text-color': '#ffffff',
        'dialog-content-color': '#ffffff',
        'dialog-content-text-color': '#000A1B',
        'header-button-color': '#ffffff',
        'header-button-text-color': '#08215c',
        'header-success-button-hover-color': '#377A00',
        'header-success-button-text-hover-color': '#08215c',
        'header-fail-button-hover-color': '#892100',
        'header-fail-button-text-hover-color': '#08215c'
    }
];
